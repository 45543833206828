nav a {
    text-decoration: none;
    color: inherit;
}

.actions {
    text-decoration: none;
    color: inherit;
}

.inlineIcon {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 5px;
}

.shortText {
    text-overflow: ellipsis;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
}